import { Injectable } from '@angular/core';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { ReplaySubject, lastValueFrom, take } from 'rxjs';
import {
  DeviceApiType,
  DeviceApiTypeExtended,
  DeviceBaseType,
  DeviceCmsType,
  DeviceInfoPayload,
  DeviceSpecificType
} from './types';

@Injectable({ providedIn: 'root' })
export class DeviceInfo {
  private _deviceInfoPayload$: ReplaySubject<DeviceInfoPayload> = new ReplaySubject<DeviceInfoPayload>(1);

  constructor(private _workerSynchronizerSpawner: WorkerSynchronizerSpawner) {
    const deviceInfoPayloadChannel: WorkerSynchronizer<DeviceInfoPayload> =
      this._workerSynchronizerSpawner.spawn('deviceInfoPayload');
    deviceInfoPayloadChannel.message$.subscribe((deviceInfoPayload: DeviceInfoPayload) => {
      this._deviceInfoPayload$.next(deviceInfoPayload);
    });
  }

  private _getDeviceInfoPayload(): Promise<DeviceInfoPayload> {
    return lastValueFrom(this._deviceInfoPayload$.pipe(take(1)));
  }

  public async getBaseType(): Promise<DeviceBaseType> {
    return (await this._getDeviceInfoPayload()).baseType;
  }

  public async getApiType(): Promise<DeviceApiType> {
    return (await this._getDeviceInfoPayload()).apiType;
  }

  public async getApiTypeExtended(): Promise<DeviceApiTypeExtended> {
    return (await this._getDeviceInfoPayload()).apiTypeExtended;
  }

  public async getCmsType(): Promise<DeviceCmsType> {
    return (await this._getDeviceInfoPayload()).cmsType;
  }

  public async getSpecificType(): Promise<DeviceSpecificType> {
    return (await this._getDeviceInfoPayload()).specificType;
  }

  public async getIsMobile(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isMobile;
  }

  public async getIsMobileApp(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isMobileApp;
  }

  public async getIsIos(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isIos;
  }

  public async getIsPwa(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isPwa;
  }

  public async getIsSafari(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isSafari;
  }

  public async getIsAndroid(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isAndroid;
  }

  public async getIsAndroidPwa(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isAndroidPwa;
  }

  public async getIsIOSPwa(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isIOSPwa;
  }

  public async getIsAndroidNative(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isAndroidNative;
  }

  public async getIsIOSNative(): Promise<boolean> {
    return (await this._getDeviceInfoPayload()).isIOSNative;
  }
}
