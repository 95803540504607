import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { TwaNativeApiClient } from '@bcf-v2-platforms/trusted-web-activity/twa-native-api-client';
import { DeviceInfo } from '@bcf-v2-utilities/device-info/device-info';
import { ExternalRedirectorConfig } from './external-redirector.service';

class OpenUrlInBrowserPayload {
  constructor(public readonly url: string) {}

  public toJson(): Record<string, string> {
    return {
      action: 'open-url',
      url: this.url
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ExternalRedirectorTwaService {
  constructor(
    private _twaNativeApiClient: TwaNativeApiClient,
    private _deviceInfo: DeviceInfo,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window
  ) {}

  public async redirectToExternalUrl(url: string, config?: ExternalRedirectorConfig): Promise<void> {
    const isAndroid: boolean = await this._deviceInfo.getIsAndroid();
    if (isAndroid) {
      this._window.open(url, config?.openInNewTab ? '_blank' : '_self');
      return;
    }
    const parsedUrl: URL | undefined = this._parseUrl(url);
    if (!parsedUrl) {
      this._window.open(url, config?.openInNewTab ? '_blank' : '_self');
      return;
    }
    if (parsedUrl.hostname === this._document.location.hostname) {
      this._window.open(url, config?.openInNewTab ? '_blank' : '_self');
      return;
    }
    this._twaNativeApiClient.sendMessage(new OpenUrlInBrowserPayload(parsedUrl.toString()).toJson());
  }

  // needed for PLIX payment method
  private _parseUrl(url: string): URL | undefined {
    try {
      const objectUrl: URL = new URL(url);
      const fragment: string = objectUrl.hash;
      if (fragment.includes('|')) {
        const splittedFragment: string[] = fragment.split('|');
        const encodedFragment: string = splittedFragment.join(encodeURIComponent('|'));
        objectUrl.hash = encodedFragment;
      }
      return objectUrl;
    } catch (err) {}
    return undefined;
  }
}
