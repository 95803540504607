import { InjectionToken } from '@angular/core';
import { CookieConsentGamblingAdult, CookieConsentOption } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COOKIE_CONSENT_DISABLED: InjectionToken<boolean> = new InjectionToken<boolean>('cookieConsentDisabled', {
  factory: () => false
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COOKIE_CONSENT_DISABLED_ADDITONAL_URLS: InjectionToken<string[]> = new InjectionToken<string[]>(
  'cookieConsentDisabledAdditionalUrls'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COOKIE_CONSENT_GAMBLING_ADULT: InjectionToken<CookieConsentGamblingAdult | undefined> = new InjectionToken<
  CookieConsentGamblingAdult | undefined
>('cookieConsentGamblingAdult', { factory: () => undefined });

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COOKIE_CONSENT_OPTIONS: InjectionToken<CookieConsentOption[]> = new InjectionToken<CookieConsentOption[]>(
  'cookieConsentOptions',
  {
    factory: () => [
      {
        type: 'Necessary',
        title: $localize`Necessary`,
        subTitle: $localize`Essential for the website's basic functionality.`
      },
      {
        type: 'Preferences',
        title: $localize`Preferences`,
        subTitle: $localize`Remember your choices for a better browsing experience.`
      },
      {
        type: 'Analytics',
        title: $localize`Analytics`,
        subTitle: $localize`Gather insights into website usage for performance improvement.`
      },
      {
        type: 'Marketing',
        title: $localize`Marketing`,
        subTitle: $localize`Track user activity for displaying relevant ads.`
      }
    ]
  }
);
