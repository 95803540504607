import { Inject, Injectable, Optional } from '@angular/core';
import { MyBetsCount } from '@bcf-v2-api-access/bp-api/models/my-bets-count';
import { ExtraTerms } from '@bcf-v2-api-access/cp-api/models/extra-terms';
import { LoginInfo } from '@bcf-v2-api-access/cp-api/models/login-info';
import { UserBalance } from '@bcf-v2-api-access/cp-api/models/user-balance';
import { UserInfo } from '@bcf-v2-api-access/cp-api/models/user-info';
import { UserInfoDetails } from '@bcf-v2-api-access/cp-api/models/user-info-details';
import { UserInfoNotLoggedIn } from '@bcf-v2-api-access/cp-api/models/user-info-not-logged-in';
import { WalletInfoMisc } from '@bcf-v2-api-access/cp-api/models/wallet-info-misc';
import { SERVER_COUNTRY_IP } from '@bcf-v2-platforms/platform-server/token';
import { filterIsTruthy } from '@bcf-v2-shared/misc/rx-operators/filter-is-truthy';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  defer,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataInfo {
  public myBetsCount$: BehaviorSubject<MyBetsCount | undefined> = new BehaviorSubject<MyBetsCount | undefined>(
    undefined
  );

  public loginInfo$: BehaviorSubject<LoginInfo | undefined> = new BehaviorSubject<LoginInfo | undefined>(undefined);
  public userInfo$: ReplaySubject<UserInfo | undefined> = new ReplaySubject<UserInfo | undefined>(1);
  public userInfoNotLoggedIn$: ReplaySubject<UserInfoNotLoggedIn | undefined> = new ReplaySubject<
    UserInfoNotLoggedIn | undefined
  >(1);
  public userInfoDetails$: ReplaySubject<UserInfoDetails | undefined> = new ReplaySubject<UserInfoDetails | undefined>(
    1
  );
  public walletInfoMisc$: BehaviorSubject<WalletInfoMisc | undefined> = new BehaviorSubject<WalletInfoMisc | undefined>(
    undefined
  );
  public userBalance$: ReplaySubject<UserBalance | undefined> = new ReplaySubject<UserBalance | undefined>(1);
  public extraTerms$: ReplaySubject<ExtraTerms | undefined> = new ReplaySubject<ExtraTerms | undefined>(1);
  public lastLoginTime$: ReplaySubject<number | undefined> = new ReplaySubject<number | undefined>(1);

  constructor(@Optional() @Inject(SERVER_COUNTRY_IP) private _serverCountryIp?: string) {}

  public countryIp$: Observable<string> = defer(() =>
    this._serverCountryIp
      ? of(this._serverCountryIp)
      : combineLatest([
          this.userInfo$.pipe(startWith(undefined)),
          this.userInfoNotLoggedIn$.pipe(startWith(undefined))
        ]).pipe(
          filter(
            ([userInfo, userInfoNotLoggedIn]: [UserInfo | undefined, UserInfoNotLoggedIn | undefined]) =>
              Boolean(userInfo) || Boolean(userInfoNotLoggedIn)
          ),
          map(
            ([userInfo, userInfoNotLoggedIn]: [UserInfo | undefined, UserInfoNotLoggedIn | undefined]) =>
              userInfo?.countryIp ?? userInfoNotLoggedIn?.countryIp
          ),
          filterIsTruthy(),
          distinctUntilChanged()
        )
  );

  public userCountry$: Observable<string | undefined> = defer(() =>
    this._serverCountryIp
      ? of(this._serverCountryIp)
      : combineLatest([
          this.userInfo$.pipe(startWith(undefined)),
          this.userInfoNotLoggedIn$.pipe(startWith(undefined))
        ]).pipe(
          filter(
            ([userInfo, userInfoNotLoggedIn]: [UserInfo | undefined, UserInfoNotLoggedIn | undefined]) =>
              Boolean(userInfo) || Boolean(userInfoNotLoggedIn)
          ),
          map(
            ([userInfo, userInfoNotLoggedIn]: [UserInfo | undefined, UserInfoNotLoggedIn | undefined]) =>
              userInfo?.countryCode ?? userInfoNotLoggedIn?.countryIp
          ),
          distinctUntilChanged()
        )
  );
}
