import { ActivatedRoute, Params } from '@angular/router';
import { UserInfoDetails } from '@bcf-v2-api-access/cp-api/models/user-info-details';
import { UserDataInfoSyncedLegacy } from '@bcf-v2-logic-worker-synced-auth/services/user-data-info.synced-legacy';
import { filterIsTruthy } from '@bcf-v2-shared/misc/rx-operators/filter-is-truthy';
import { CurrentLang } from '@bcf-v2-utilities/current-lang/current-lang';
import { LocalStorageKeys } from '@bcf-v2-utilities/local-storage/enums';
import { LocalStorageService } from '@bcf-v2-utilities/local-storage/local-storage.service';
import { first, lastValueFrom } from 'rxjs';
import { TwaNativeApiClient } from './twa-native-api-client';

type TwaPayload = {
  twaMode: 'ios-webview' | 'android-webview' | 'android-ws';
  twaSocketAddress: string | undefined;
  twaSocketPort: number | undefined;
};

async function getTwaPayload(
  localStorageService: LocalStorageService,
  activatedRoute: ActivatedRoute
): Promise<TwaPayload> {
  const twaModeFromSessionStorage: string | undefined =
    localStorageService.getString(LocalStorageKeys.twaMode) ?? undefined;
  if (twaModeFromSessionStorage) {
    return {
      twaMode: twaModeFromSessionStorage as 'ios-webview' | 'android-webview' | 'android-ws',
      twaSocketAddress: localStorageService.getString(LocalStorageKeys.twaSocketAddress) ?? undefined,
      twaSocketPort: localStorageService.getNumber(LocalStorageKeys.twaSocketPort)
    };
  }
  const queryParams: Params = await lastValueFrom(
    activatedRoute.queryParams.pipe(first((params: Params) => params['twaMode']))
  );
  localStorageService.setString(LocalStorageKeys.twaMode, queryParams['twaMode']);
  if (queryParams['twaSocketAddress'] && queryParams['twaSocketPort']) {
    localStorageService.setString(LocalStorageKeys.twaSocketAddress, queryParams['twaSocketAddress']);
    localStorageService.setNumber(LocalStorageKeys.twaSocketPort, Number(queryParams['twaSocketPort']));
  }
  return {
    twaMode: queryParams['twaMode'],
    twaSocketAddress: queryParams['twaSocketAddress'],
    twaSocketPort: Number(queryParams['twaSocketPort'])
  };
}

async function updateNativeAppPreferences(
  currentLang: CurrentLang,
  twaNativeApiClient: TwaNativeApiClient
): Promise<void> {
  const lang: string = await currentLang.getBaseLang();
  const preferences: Record<string, string> = { routeLanguage: lang };
  twaNativeApiClient.sendMessage({ action: 'update-preferences', preferences });
}

function updateNativeAppUserData(userDataInfo: UserDataInfoSyncedLegacy, twaNativeApiClient: TwaNativeApiClient): void {
  userDataInfo.userInfoDetails$.pipe(filterIsTruthy()).subscribe((userDetails: UserInfoDetails) => {
    twaNativeApiClient.sendMessage({
      action: 'update-user-data',
      data: { email: userDetails.email, walletId: userDetails.walletId }
    });
  });
}

// eslint-disable-next-line @typescript-eslint/typedef
export const initTwaDeps = [
  TwaNativeApiClient,
  ActivatedRoute,
  LocalStorageService,
  CurrentLang,
  UserDataInfoSyncedLegacy
];

export function initTwa(
  twaNativeApiClient: TwaNativeApiClient,
  activatedRoute: ActivatedRoute,
  localStorageService: LocalStorageService,
  currentLang: CurrentLang,
  userDataInfo: UserDataInfoSyncedLegacy
) {
  return async (): Promise<void> => {
    const twaPayload: TwaPayload = await getTwaPayload(localStorageService, activatedRoute);
    twaNativeApiClient.initialize(twaPayload.twaMode, twaPayload.twaSocketAddress, twaPayload.twaSocketPort);
    updateNativeAppPreferences(currentLang, twaNativeApiClient);
    updateNativeAppUserData(userDataInfo, twaNativeApiClient);
  };
}
