import { Injectable } from '@angular/core';
import { MyBetsCount } from '@bcf-v2-api-access/bp-api/models/my-bets-count';
import { ExtraTerms } from '@bcf-v2-api-access/cp-api/models/extra-terms';
import { LoginInfo } from '@bcf-v2-api-access/cp-api/models/login-info';
import { UserBalance } from '@bcf-v2-api-access/cp-api/models/user-balance';
import { UserInfo } from '@bcf-v2-api-access/cp-api/models/user-info';
import { UserInfoDetails } from '@bcf-v2-api-access/cp-api/models/user-info-details';
import { UserInfoNotLoggedIn } from '@bcf-v2-api-access/cp-api/models/user-info-not-logged-in';
import { WalletInfoMisc } from '@bcf-v2-api-access/cp-api/models/wallet-info-misc';
import { UserDataInfo } from '@bcf-v2-logic-worker-auth/services/user-data-info';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { transferRxNext } from '@bcf-v2-shared/misc/rx-helpers/transfer-rx-next';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataInfoSyncedLegacy extends UserDataInfo {
  public override countryIp$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public override userCountry$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);

  private _loginInfoChannel: WorkerSynchronizer<LoginInfo | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerLoginInfoSynced');

  private _myBetsCountChannel: WorkerSynchronizer<MyBetsCount | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerMyBetsCountSynced');

  private _userInfoChannel: WorkerSynchronizer<UserInfo | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoSynced');

  private _userInfoChannel2: WorkerSynchronizer<UserInfo | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoSynced');

  private _userInfoNotLoggedInChannel: WorkerSynchronizer<UserInfoNotLoggedIn | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoNotLoggedInSynced');

  private _userUserInfoDetailsChannel: WorkerSynchronizer<UserInfoDetails | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoDetailsSynced');

  private _userWalletInfoMiscChannel: WorkerSynchronizer<WalletInfoMisc | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerWalletInfoMiscSynced');

  private _userUserBalanceChannel: WorkerSynchronizer<UserBalance | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserBalanceSynced');

  private _userExtraTermsChannel: WorkerSynchronizer<ExtraTerms | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerExtraTermsSynced');

  private _userCountryIpChannel: WorkerSynchronizer<string> =
    this._workerSynchronizerSpawner.spawn('_workerCountryIpSynced');

  private _userCountryChannel: WorkerSynchronizer<string | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerCountrySynced');

  constructor(private _workerSynchronizerSpawner: WorkerSynchronizerSpawner) {
    super();
    this._init();
  }

  private _init(): void {
    this._loginInfoChannel.message$.subscribe(transferRxNext(this.loginInfo$));
    this._myBetsCountChannel.message$.subscribe(transferRxNext(this.myBetsCount$));
    this._userInfoChannel.message$.subscribe(transferRxNext(this.userInfo$));

    this._userInfoChannel2.message$.subscribe(transferRxNext(this.userInfo$));

    this._userInfoNotLoggedInChannel.message$.subscribe(transferRxNext(this.userInfoNotLoggedIn$));
    this._userUserInfoDetailsChannel.message$.subscribe(transferRxNext(this.userInfoDetails$));
    this._userWalletInfoMiscChannel.message$.subscribe(transferRxNext(this.walletInfoMisc$));
    this._userUserBalanceChannel.message$.subscribe(transferRxNext(this.userBalance$));
    this._userExtraTermsChannel.message$.subscribe(transferRxNext(this.extraTerms$));
    this._userCountryIpChannel.message$.subscribe(transferRxNext(this.countryIp$));
    this._userCountryChannel.message$.subscribe(transferRxNext(this.userCountry$));
  }
}
