import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

export function normalizeQueryParams(params: string): string {
  return params && params[0] !== '?' ? '?' + params : params;
}

@Injectable({ providedIn: 'root' })
export class TwaPathLocationStrategy extends PathLocationStrategy {
  constructor(platformLocation: PlatformLocation, @Optional() @Inject(APP_BASE_HREF) href?: string) {
    super(platformLocation, href);
  }

  public override pushState(state: Record<string, any>, title: string, url: string, _queryParams: string): void {
    super.pushState(state, title, url, '');
  }

  public override replaceState(state: Record<string, any>, title: string, url: string, _queryParams: string): void {
    super.replaceState(state, title, url, '');
  }
}
