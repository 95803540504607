import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Cookies } from '@bcf-v2-utilities/cookies-provider/cookies';

/**
 * this is needed for inbox, in inbox we have url like https://user.lvbet.lv/inbox/url/294647/
 * we need cookies session for opening this url
 * IOS dont share cookies between webview and safari controller or system browser
 * we cant event set cookies manually before opening safari controller
 * so we extract cookies from ios webview, then insert in into queryParams as json string
 * then we parse in here, and make redirect to correct url with cookies
 *
 */

type CookiesForRedirect = {
  name: string;
  value: string;
  expiresDate: string;
};

export function provideTwaRedirectorWithCookies(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: (document: Document, cookies: Cookies) => () => {
        const params: URLSearchParams = new URLSearchParams(document.location.search);
        if (params.has('cookiesToSet') && params.has('redirectTo')) {
          const parsedCookies: CookiesForRedirect[] = JSON.parse(params.get('cookiesToSet')!);
          const redirectUrl: string = params.get('redirectTo')!;
          const domain: string = `.${document.location.hostname.split('.').slice(1).join('.')}`;

          for (const cookie of parsedCookies) {
            cookies.set(cookie.name, cookie.value, new Date(cookie.expiresDate), undefined, domain);
          }
          window.location.href = redirectUrl;
        }
      },
      deps: [DOCUMENT, Cookies],
      multi: true
    }
  ]);
}
