import { InjectionToken } from '@angular/core';

export type ServerResponseAction = {
  setStatus: (status: number) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SERVER_RESPONSE_ACTION: InjectionToken<ServerResponseAction> = new InjectionToken<ServerResponseAction>(
  'ServerResponseAction'
);
