export enum LocalStorageKeys {
  twaMode = 'twaMode',
  twaSocketAddress = 'twaSocketAddress',
  twaSocketPort = 'twaSocketPort',
  appUnlocked = 'appUnlocked',
  fcmToken = 'fcmToken',
  terminalPrivateKey = 'terminalPrivateKey',
  deviceName = 'deviceName',
  betslipSelectionsIds = 'betslipSelectionsIds',
  betslipMatchesIdsLive = 'betslipMatchesIdsLive',
  betslipMatchesIdsPre = 'betslipMatchesIdsPre',
  betslipMarketsIds = 'betslipMarketsIds',
  registrationWizardFormValue = 'registrationWizardFormValue',
  favoritesLeagues = 'favoritesLeagues',
  favoritesPlayers = 'favoritesPlayers',
  favoritesMarketsTypesIdsCustom = 'favoritesMarketsTypesIdsCustom',
  emoneyCardId = 'emoneyCardId',
  pinLoginPayload = 'pinLoginPayload',
  loginPayload = 'lp@@!',
  depositTransactionDetails = 'depositTransactionDetails',
  epsonPrinterIpAddress = 'epsonPrinterIpAddress',
  terminalMessage = 'terminalMessage',
  showPlaceBetMax = 'showPlaceBetMax',
  modalConfirmLocationConfirmed = 'modalConfirmLocationConfirmed',
  pwaInstallBarDismissedDate = 'pwaInstallBarDimissedDate',
  pwaInstalledIOS = 'pwaInstalledIOS',
  lowBalanceBoxVisible = 'lowBalanceBoxVisible',
  oddsFormat = 'oddsFormat'
}
