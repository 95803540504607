import { Inject, Injectable } from '@angular/core';
import { LOCALSTORAGE } from '@bcf-v2-platforms/platform-apis/local-storage-provider';
import { Observable, Subject } from 'rxjs';
import { LocalStorageKeys } from './enums';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _didUpdate$: Subject<void> = new Subject<void>();

  constructor(@Inject(LOCALSTORAGE) private _localStorage: Storage) {}

  public clear(): void {
    this._localStorage.clear();
  }

  public hasKey(key: LocalStorageKeys): boolean {
    return this._localStorage.getItem(key) === null ? false : true;
  }

  public getString(key: LocalStorageKeys): string | null {
    return this._localStorage.getItem(key);
  }

  public setString(key: LocalStorageKeys, value: string): void {
    this._localStorage.setItem(key, value);
    this._didUpdate$.next(undefined);
  }

  public setDate(key: LocalStorageKeys, value: Date, additionalKey?: string | number): void {
    const mergedKey: string = additionalKey ? `${key}_${additionalKey}` : key;
    this._localStorage.setItem(mergedKey, value.toISOString());
    this._didUpdate$.next(undefined);
  }

  public getDate(key: LocalStorageKeys, additionalKey?: string | number): Date | null {
    const mergedKey: string = additionalKey ? `${key}_${additionalKey}` : key;
    const value: string | null = this._localStorage.getItem(mergedKey);
    if (value !== null) {
      return new Date(value);
    }
    return null;
  }

  public getNumber(key: LocalStorageKeys): number {
    return Number(this._localStorage.getItem(key));
  }

  public setNumber(key: LocalStorageKeys, value: number): void {
    this._localStorage.setItem(key, value?.toString());
    this._didUpdate$.next(undefined);
  }

  public getBoolean(key: LocalStorageKeys): boolean {
    return this._localStorage.getItem(key) === 'true';
  }

  public setBoolean(key: LocalStorageKeys, value: boolean): void {
    this._localStorage.setItem(key, value.toString());
    this._didUpdate$.next(undefined);
  }

  public getJson<T>(key: LocalStorageKeys): T | undefined {
    const value: string | null = this._localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  public setJson<T>(key: LocalStorageKeys, value: T): void {
    this._localStorage.setItem(key, JSON.stringify(value));
    this._didUpdate$.next(undefined);
  }

  public remove(key: LocalStorageKeys): void {
    this._localStorage.removeItem(key);
    this._didUpdate$.next(undefined);
  }

  public didUpdated(): Observable<void> {
    return this._didUpdate$.asObservable();
  }
}
