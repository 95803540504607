import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';

export type ExternalRedirectorConfig = {
  openInNewTab?: boolean;
  replaceUrl?: boolean;
  mobileAppSettings?: Record<string, string>;
};
@Injectable({ providedIn: 'root' })
export class ExternalRedirectorService {
  constructor(@Inject(WINDOW) private _window: Window) {}

  public redirectToExternalUrl(url: string, config?: ExternalRedirectorConfig): void {
    if (config?.replaceUrl) {
      this._window.location.replace(url);
      return;
    }
    this._window.open(url, config?.openInNewTab ? '_blank' : '_self');
  }
}
