import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { Observable, Subject } from 'rxjs';

import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

type TwaMode = 'ios-webview' | 'android-webview' | 'android-ws';

type WindowWithMessager = {
  webkit: {
    messageHandlers: {
      twaMessageChannel: {
        postMessage: (payload: Record<string, any>) => void;
      };
    };
  };
  twaMessageChannelOnMessage: (jsonString: string) => void;
  TwaAndroid: {
    postMessage: (message: string) => void;
  };
  atob: (str: string) => string;
};

@Injectable({
  providedIn: 'root'
})
export class TwaNativeApiClient {
  private _socketAddress: string | undefined;
  private _socketPort: number | undefined;
  private _mode!: TwaMode;

  private _socketRef$!: WebSocketSubject<Record<string, any>>;

  private _webViewModeMessage$: Subject<Record<string, any>> = new Subject<Record<string, any>>();

  constructor(@Inject(WINDOW) private _window: WindowWithMessager) {}

  public initialize(twaMode: TwaMode, socketAddress: string | undefined, socketPort: number | undefined): void {
    this._socketAddress = socketAddress;
    this._socketPort = socketPort;
    this._mode = twaMode;

    if (this._mode.includes('ios-webview') || this._mode.includes('android-webview')) {
      this._window.twaMessageChannelOnMessage = (message: string): void => {
        const jsonString: string = this._window.atob(message);
        this._webViewModeMessage$.next(JSON.parse(jsonString));
      };
    }
  }

  public getMessage(): Observable<Record<string, any>> {
    if (this._mode.includes('ios-webview') || this._mode.includes('android-webview')) {
      return this._webViewModeMessage$.asObservable();
    }
    return this._socket$.asObservable();
  }

  public sendMessage(message: Record<string, any>): void {
    if (this._mode.includes('ios-webview')) {
      try {
        this._window.webkit.messageHandlers.twaMessageChannel.postMessage(message);
      } catch (err) {}
      return;
    }
    if (this._mode.includes('android-webview')) {
      try {
        this._window.TwaAndroid.postMessage(JSON.stringify(message));
      } catch (err) {}
      return;
    }
    this._socket$.next(message);
  }

  private get _socket$(): WebSocketSubject<Record<string, any>> {
    if (!this._socketRef$) {
      this._socketRef$ = webSocket(`ws://${this._socketAddress}:${this._socketPort}`);
      this._socketRef$.subscribe();
    }
    return this._socketRef$;
  }
}
